import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`“Innovation in transportation”`}</p>
    <p>{`We are a young IT startup that does software development for the transportation sector.
In our core we are a TMS system. On top of that we offer integrations, real time tracking, end2end visibility and artificial planning.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      