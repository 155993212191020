import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="AI & cloud" link="https://vdab.be/embed/vindeenjob/vacatures/61707512" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Are you crazy about cloud technology, artificial intelligence and software?
  Yes? So are we! And we want your craziness.
    </ProjectCard>
    <ProjectCard title="Web Development & cloud" link="https://vdab.be/embed/vindeenjob/vacatures/61707459" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Do you also have a passion for software, cloud technology and user experience?
  Yes? So do we! Come and share that passion with us.
    </ProjectCard>
    <ProjectCard title="IoT & cloud" link="https://www.vdab.be/embed/vindeenjob/vacatures/61707645" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Are you a software developer and a Maker? Do you love hands-on work with real
  impact. Yes? So do we! Join us in our quest towards a more ecological world.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      